import React from 'react'
import './plans.css'
import Header from '../../componets/Header'
import HeaderImage from '..//..//images/header_bg_4.jpg'
import Card from '../../UI/Card'
import { plans } from '../../data'
import { Link } from 'react-router-dom'

const Plans = () => {
	return (
		<>
			<Header title="Our Plan" image={HeaderImage}></Header>

			<section className="plans">
				<div className="containers plans__container">
					{
							plans.map(({ id, name, desc, price, features}) =>{
							return (
								<Card key={id}>
									<h3>{name}</h3>
									<small>{desc}</small>
									<h1>{`$${price}`}</h1>
									<h2>/mo</h2>
									<h4>features</h4>

									{
											features.map(({ feature, available }, index) => {
											return (
												<p key={index} className={!available ? 'disabled' : ''}>
													{feature}
												</p>
											);
										})
									}
									<Link to="/contact" className="btns lg">
										{' '}
										Choose Plan
									</Link>
								</Card>
							);
						})
					}
				</div>
			</section>
		</>
	);
} 
 
export default Plans