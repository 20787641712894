import React from 'react'
import Sectionhead from './Sectionhead'
import { faqs } from '../data'
import FAQ from './FAQ'
import { FaCrown } from 'react-icons/fa';


const Faqs = () => {
	return (
		<section className="faqs">
			<div className="containers faqs__container">
				<Sectionhead icon={<FaCrown/>} title="FAQs" />
				<div className="faqs__wrapper">
					{
							faqs.map(({id,question,answer})=>{
								return <FAQ key={id} question={question} answer={answer}/>
							})
					}
				</div>
			</div>
		</section>
	);
}

export default Faqs