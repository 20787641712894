import React, { useState } from 'react'
import { FiAlignJustify } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import './navbar.css'
import {  Link, NavLink } from 'react-router-dom';
import logo from '../images/logo.png'
import {links} from '../data'

const Navbar = ({ setShowLogin }) => {

	
	const [isNavShowing, setIsNavShowing] = useState(false);

	return (
		<>
			<nav>
				<div className="logo containers nav__container">
					<Link to="/" className="nav-c" onClick={() => setIsNavShowing(false)}>
						<img src={logo} alt="Logo" />
					</Link>
					{/* <ul className= {`nav__links ${isNavShowing ? 'show__nav' : 'hide__Nav'}`> */}
					<ul
						className={`nav__links ${isNavShowing ? 'show__nav' : 'hide__Nav'}`}
					>
						{links.map(({ name, path }, index) => {
							return (
								<li key={index}>
									<NavLink
										to={path}
										className={({ isActive }) => (isActive ? 'active-nav' : '')}
										onClick={() => setIsNavShowing((perv) => !perv)}
									>
										{name}
									</NavLink>
								</li>
							);
						})}
					</ul>
					<button className="login" onClick={() => setShowLogin(true)}>
						Login
					</button>
					<button
						className="nav__toggle-btn"
						onClick={() => setIsNavShowing((perv) => !perv)}
					>
						{/* <FiAlignJustify /> */}
						{isNavShowing ? <IoMdClose /> : <FiAlignJustify />}
					</button>
				</div>
			</nav>
			{/* <Outlet /> */}
		</>
	);
};

export default Navbar