import React from 'react'
import './about.css'
import Header from '../../componets/Header'
import HeaderImage from '../../images/header_bg_1.jpg'
import storyabout from '../../images/ourstory.jpg'
import VisionImage from '../../images/classes.png';
import missionabout from '../../images/ctrining.jpg'

const About = () => {
	return (
		<>
			<Header title="About Us" image={HeaderImage}>
				Know About Best Fitness Center in Bettiah
			</Header>

			<section className="about__story">
				<div className="containers about_story-container">
					<div className="about__section-image" id="ourstory">
						<img src={storyabout} alt="about__image" />
					</div>
					<div className="about__section-content">
						<h1>Our Story</h1>
						<p>
							We're your partner in transformation. Our modern facility offers
							everything you need: cardio equipment, free weights, strength
							training zones, and expert trainers who craft personalized workout
							plans. Additionally.
						</p>
						<p>
							Many women still think that the areas of free weight are only for
							men because they are exercises that will make you masculine or
							simply because they consider it too much for them simply because
							they are women, just as many men think that any collective class .
						</p>
					</div>
				</div>
			</section>
			<hr className="hr" />

			<section className="about__Vision">
				<div className="containers about_Vision-container">
					<div className="about__section-content">
						<h1>Group Exercise Classes</h1>
						<p>
							ur range of classes will inspire you to explore new training
							styles and techniques, while keeping you motivated with other
							like-minded members.
						</p>
						<p>
							We have a wide choice of classes that are a great complement to
							any training programme.
						</p>
					</div>
					<div className="about__section-image">
						<img src={VisionImage} alt="Vision__image" />
					</div>
				</div>
			</section>
			<hr className="hr" />

			<section className="about__mission">
				<div className="containers about_mission-container">
					<div className="about__section-image mission-image">
						<img src={missionabout} alt="mission__image" />
					</div>
					<div className="about__section-content">
						<h1>Cardio Training</h1>
						<p>
							Whether you want increased energy or reduced stress, cardio
							training (otherwise known as cardiovascular or CV training) helps
							you live a fitter life while strengthening your heart and lungs.
						</p>
					</div>
				</div>
			</section>
		</>
	);
}

export default About