import React from 'react'
import Sectionhead from './Sectionhead'
import { values } from '../data'
import Card from '../UI/Card'
import image from '../images/values.jpg'
import { FaCrown } from 'react-icons/fa';


const Values = () => {
	return (
		<section className="values">
			<div className="containers values__container">
				<div className="values__left">
					<div className="values__image">
						<img src={image} alt="" />
					</div>
				</div>
				<div className="values__right">
					<Sectionhead icon={<FaCrown />} title="Values"/>
					<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, iste eum? Maiores dicta repudiandae </p>
					<div className="values__wrapper">
						{
							values.map(({id,icon,title,desc})=>{
								return <Card  key={id} className="values__value">
										<span> {icon}</span>
										<h4>{title}</h4>
										<small>{desc}</small>
								</Card>
							})
						}
					</div>
				</div>
			</div>
		</section>
	);
}

export default Values