import React from 'react'
import './home.css'
import MainHeader from '../../componets/MainHeader';
import Programs from '../../componets/Programs';
import Values from '../../componets/Values';
import Faqs from '../../componets/Faqs';
import Testimonials from '../../componets/Testimonials';

const Home = () => {
	return (
		<div>
			<MainHeader />
			<Programs />
			<Values />
			<Faqs />
			<Testimonials />
		</div>
	);
}

export default Home