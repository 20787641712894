import {SiOpenaigym} from 'react-icons/si'
import { GrYoga } from 'react-icons/gr';
import {IoIosBody } from 'react-icons/io';
import { IoFastFood } from 'react-icons/io5';



export const links = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "About",
        path: '/about'
    },
    {
        name: "Gallery",
        path: '/gallery'
    },
     {
         name: "Plans",
         path: '/plans'
     },
    {
        name: "Trainers",
        path: '/trainers'
    },
    {
        name: "Contact",
        path: '/contact'
    }
]







export const programs = [
	{
		id: 1,
		icon: <IoIosBody />,
		title: 'Bodybuilding',
		info: 'A training methodology that creates stimulus for improved strength & endurance while helping participants build a great physique, improved range of motion and all round functional fitness.',
		path: '/programs/111',
	},
	{
		id: 2,
		icon: <IoFastFood />,
		title: 'Diet & Nutrition',
		info: 'That’s why our experts work with you to develop customized nutrition plans that focus on your specific needs, tastes, and dietary restrictions.',
		path: '/programs/222',
	},
	{
		id: 3,
		icon: <SiOpenaigym />,
		title: 'Zumba',
		info: 'Get into the groove with our high-energy dance workouts, ranging in skill-level from easy-to-follow to dance party Zumba choreography.',
		path: '/programs/333',
	},
	{
		id: 4,
		icon: <GrYoga />,
		title: 'Yoga',
		info: 'Every class has an array of breathing techniques, a variety of asanas and meditation techniques. Helps in improving confidence & balance while gaining a stronger body in the process.',
		path: '/programs/444',
	},
];








export const values = [
	{
		id: 1,
		icon: <SiOpenaigym />,
		title: 'Personal Training',
		desc: 'Our gym offers personalized training sessions and customized.',
	},
	{
		id: 2,
		icon: <SiOpenaigym />,
		title: 'Equipment and facilities',
		desc: 'We are committed to providing you with High modern equipment and facilities.',
	},
	{
		id: 3,
		icon: <SiOpenaigym />,
		title: 'Nutrition counselling',
		desc: 'Anthropometric Assessment (Weight, Height, body circumference, etc) by our expert',
	},
	// {
	// 	id: 4,
	// 	icon: <SiOpenaigym />,
	// 	title: 'Value Four',
	// 	desc: 'Placeat quidem facere dicta modi? Pariatur exercitationem illum.',
	// },
];









export const faqs = [
	{
		id: 1,
		question: 'How does a fitness club creates revenue ?',
		answer:
			'The majority of your revenue will come from membership sales. We will recommend a monthly dues program with a one-time enrollment fee. Each member has the option to pay in full or pay dues each month via EFT (Electronic Funds Transfer). An EFT payment can be made using a credit card, checking account, or savings account. Your membership rates may vary based on area demographics and the offerings at your facility.',
	},
	{
		id: 2,
		question: 'What can I expect to make from my business ?',
		answer:
			'Income from each Fitness club varies based on many variables including market size, demographics, efforts made by owners, competency, and economic factors.* We can put you in touch with many different Fitness owners, ',
	},
	{
		id: 3,
		question: 'How old do I have to be to join Fitness? ?',
		answer:
			'To join you must be 16 years old and above. We will only entertain members under 16 if they are obese, are under doctor advisement and take up personal training. All membership agreements for children under 16 years of age need to be authorised and signed by a legal guardian.',
	},
	{
		id: 4,
		question: 'Do I need to warm up before my workouts?',
		answer:
			'Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit, consequatur quibusdam dignissimos cum labore possimus placeat consectetur nisi cupiditate? Qui totam est omnis dolor nobis quisquam veritatis a!',
	},
	{
		id: 5,
		question: 'Should I do strength training, cardio or both?',
		answer:
			'Maiores fuga, cum praesentium esse laudantium! Distinctio nihil blanditiis accusantium atque, quo maxime inventore eum! Cum dolorem quibusdam amet et qui.',
	},
	{
		id: 6,
		question: 'Should I lift weights for strength training?',
		answer:
			'Quas, est at! Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit, consequatur quibusdam dignissimos cum labore possimus placeat consectetur nisi cupiditate.',
	},
];








export const testimonials = [
	{
		id: 1,
		name: 'Diana Ayi',
		quote:
			'I am working out in this gym since 1 years , me and my husband regularly workout & staff here are wonderful and helpful . they take personal care and train us well . Thanks Fitness Club',
		avatar: require('./images/avatar1.jpg'),
	},
	{
		id: 2,
		name: 'Daniel Vinyo',
		quote:
			'Very good space for workout in Wilson Garden locality, Trainers, are good, well-maintained place and they always keep upgrading equipment regularly safe place to workout since they follow covid protocols; never it is overcrowded. Waseem manages it well.',
		job: 'Software Egineer',
		avatar: require('./images/avatar2.jpg'),
	},
	{
		id: 3,
		name: 'Edem Quist',
		quote:
			'It is wonderful experience with eternal fitness. Good atmosphere, friendly and inviting. Good gym with great trainer. The coach is awesome and friendly. Well maintained equipments. Overall worth to go eternal gym.',
		job: 'University Lecturer',
		avatar: require('./images/avatar3.jpg'),
	},
	
	{
		id: 4,
		name: 'Nana Yaa Dankwa',
		quote:
			'I was 64 kgs when I had joined and after 10 months I m now 52kgs it is just not my achievement but with proper and perfect guidance',
		job: 'Pharmacist',
		avatar: require('./images/avatar5.jpg'),
	},
];







export const plans = [
    {
        id: 1,
        name: 'Silver Package',
        desc: 'This package is perfect for beginners who need constant help',
        price: 29.99,
        features: [
            {feature: 'First Feature', available: true},
            {feature: 'Second Feature', available: true},
            {feature: 'Third Feature', available: true},
            {feature: 'Fourth Feature', available: true},
            {feature: 'Fifth Feature', available: true},
            {feature: 'Fifth Feature Plus', available: false},
            {feature: 'Sixth Feature', available: false},
            {feature: 'Seventh Feature', available: false},
            {feature: 'Seventh Feature Plus', available: false},
            {feature: 'Eighth Feature', available: false},
            {feature: 'Ninth Feature', available: false},
            {feature: 'Tenth Feature', available: false},
            {feature: 'Eleventh Feature', available: false}
        ]
    },
    {
        id: 2,
        name: 'Gold Package',
        desc: 'This is the perfect package for beginners who know what their doing',
        price: 49.99,
        features: [
            {feature: 'First Feature', available: true},
            {feature: 'Second Feature', available: true},
            {feature: 'Third Feature', available: true},
            {feature: 'Fourth Feature', available: true},
            {feature: 'Fifth Feature', available: true},
            {feature: 'Fifth Feature Plus', available: true},
            {feature: 'Sixth Feature', available: true},
            {feature: 'Seventh Feature', available: true},
            {feature: 'Seventh Feature Plus', available: true},
            {feature: 'Eighth Feature', available: false},
            {feature: 'Ninth Feature', available: false},
            {feature: 'Tenth Feature', available: false},
            {feature: 'Eleventh Feature', available: false}
        ]
    },
    {
        id: 3,
        name: 'Platinum Package',
        desc: 'This package is perfect for busy people who need home service',
        price: 89.99,
        features: [
            {feature: 'First Feature', available: true},
            {feature: 'Second Feature', available: true},
            {feature: 'Third Feature', available: true},
            {feature: 'Fourth Feature', available: true},
            {feature: 'Fifth Feature', available: true},
            {feature: 'Fifth Feature Plus', available: true},
            {feature: 'Sixth Feature', available: true},
            {feature: 'Seventh Feature', available: true},
            {feature: 'Seventh Feature Plus', available: true},
            {feature: 'Eighth Feature', available: true},
            {feature: 'Ninth Feature', available: true},
            {feature: 'Tenth Feature', available: true},
            {feature: 'Eleventh Feature', available: true}
        ]
    }
]









const Trainer1 = require('./images/trainer1.jpg')
const Trainer2 = require('./images/trainer2.jpg')
const Trainer3 = require('./images/trainer3.jpg')
const Trainer4 = require('./images/trainer4.jpg')
const Trainer5 = require('./images/trainer5.jpg')
const Trainer6 = require('./images/trainer6.jpg')


export const trainers = [
    {
        id: 1,
        image: Trainer1,
        name: 'John Doe',
        job: 'Aerobic Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 2,
        image: Trainer2,
        name: 'Daniel vinyo',
        job: 'Speed Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 3,
        image: Trainer3,
        name: 'Edem Quist',
        job: 'Flexibility Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 4,
        image: Trainer4,
        name: 'Shatta Wale',
        job: 'Body Composition Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 5,
        image: Trainer5,
        name: 'Diana Ayi',
        job: 'Circuit Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 6,
        image: Trainer6,
        name: 'Wayne Carter',
        job: 'Physical Intelligence Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    }
]