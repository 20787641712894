import React from 'react'
import './notfound.css'
import {Link} from 'react-router-dom'

const Notfound = () => {
	return (
		<>
			<section className="containers notfound">
				<h2>Page Not Found</h2>
				<Link to="/" className='btn'> Go Back Home</Link>
			</section>
		</>
	)
}
 
export default Notfound