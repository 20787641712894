import React from 'react'
import './contact.css'
import Header from '../../componets/Header'
import HeaderImage from '../../images/header_bg_2.jpg'
import { MdEmail } from 'react-icons/md'
import { BsMessenger } from 'react-icons/bs'
import { IoLogoWhatsapp } from 'react-icons/io'

const Contact = () => {
	return (
		<>
			<Header title="Get In Touch" image={HeaderImage}>
				At Fitness we are always here to help. Browse the options below to
				help us direct your enquiry.
			</Header>
			<section className="contact">
				<div className="containers contact__container">
					<div className="contact__wrapper">
						<a
							href="mailto:abhinaw709@gmail.com"
							target="_blank"
							rel="noreferrer noopener"
						>
							<MdEmail />
						</a>
						<a
							href="https://m.me/ernest_achiever"
							target="_blank"
							rel="noreferrer noopener"
						>
							<BsMessenger />
						</a>
						<a
							href="mailto:abhinaw709@gmail.com"
							target="_blank"
							rel="noreferrer noopener"
						>
							<IoLogoWhatsapp />
						</a>
					</div>
				</div>
			</section>
		</>
	);
} 

export default Contact