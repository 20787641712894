import React from 'react'
import { FaCrown } from 'react-icons/fa';
import Sectionhead from './Sectionhead';
import {programs} from '../data'
import Card from '../UI/Card'
import { Link } from 'react-router-dom';

const Programs = () => { 
	return (
		<>
			<div className="programs">
				<div className=".containers programs__container">
					<Sectionhead icon={<FaCrown />} title="Programs" />

					<div className="programs__wrapper">
						{programs.map(({ id, icon, title, info, path }) => {
							return (
								<>
									<Card className="programs__program" key={id}>
										<span>{icon}</span>
										<h4>{title}</h4>
										<small>{info}</small>
										<Link to={path} className="btns sm pbtm">
											{' '}
											Learn More
										</Link>
									</Card>
								</>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
}

export default Programs