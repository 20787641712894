import React from 'react'
import { Link } from 'react-router-dom'
import image from '../images/main_header.png'

const MainHeader = () => {
	return (
		<>
			<header className="main__header">
				<div className="containers main__header-container">
					<div className="main__header-left">
						<h4>Ready TO TRAIN</h4>
						<h1>YOUR BODY</h1>
						<p id="ban-btn">
							At Fitness Club Gym, we pride ourselves on being the top-notch
							fitness center in Bettiha. Our expert trainers and cutting-edge
							programs are designed to help you transform your body.{' '}
						</p>
						<Link to="/plans" className="btns lg" >
							{' '}
							Get Started
						</Link>
					</div>
					<div className="main__header-right">
						<div className="main__header-circle"></div>
						<div className="main__header-image">
							<img src={image} alt="" />
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

export default MainHeader