import React, { useState } from 'react';
import './App.css';
// import Text from './pages/Test';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Gallery from './pages/Gallery/Gallery';
import Plans from './pages/Plans/Plans';
import Trainers from './pages/Trainers/Trainers';
import Notfound from './pages/notfound/Notfound';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Navbar from './componets/Navbar';
import Navbar from './componets/Navbar';
import Footer from './componets/Footer';
import LoginPopup from './componets/LoginPopup/LoginPopup';

function App() {
	const [showLogin, setShowLogin] = useState(false);

	return (
		<>
			{showLogin ? <LoginPopup setShowLogin={setShowLogin} /> : <></>}
			<BrowserRouter>
				<Navbar setShowLogin={setShowLogin} />
				<Routes>
					<Route index element={<Home />} />
					<Route path="about" element={<About />} />
					<Route path="Contact" element={<Contact />} />
					<Route path="Gallery" element={<Gallery />} />
					<Route path="Plans" element={<Plans />} />
					<Route path="Trainers" element={<Trainers />} />
					<Route path="Notfound" element={<Notfound />} />
				</Routes>
				<Footer />
			</BrowserRouter>
		</>
	);
}

export default App;
