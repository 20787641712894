import React from 'react'
//import { FaCrown } from 'react-icons/fa';
const Sectionhead = ({icon,title,className}) => {
	return (
		<>
			<div className={`section__head ${className}`}>
				<span>{icon}</span>
				<h2>{title} </h2>
			</div>
		</>
	);
}

export default Sectionhead